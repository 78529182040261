* {
    padding: 0px;
    margin: 0px;

}

.profile-main-wrapper{
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #F7F7F7;
    min-height: 100svh;
    display: flex;
    flex-direction: column;
    font-family: "Inter", sans-serif;

    .burger-icon {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

    .profile-login-btn {
        position: absolute;
        top: 15px;
        right: 60px;
        cursor: pointer;

        .profile-action-login-btn {
            background-color: #F47122;
        }
    }
    
    .ecard-sidebar {
        position: fixed;
        top: 0;
        right: -100%; // Initially hidden off-screen
        height: 100%;
        width: 80%;
        max-width: 350px;
        background-color: #f0f2f5;
        transition: right 0.5s ease;
        box-shadow:  0 0 10px rgba(#000000, 0.1);
        z-index: 1000;

        &.open {
            right: 0;
        }

        .sidebar-content {
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .QR-user-details {
            margin: 0 auto;
            background-color: #ffffff;
            margin-top: 20%;
            border-radius: 50%;
            text-align: center;
            width: 80%;
            height: auto;
            aspect-ratio: 1/1;
            display: flex;
            justify-content: center;
            align-items: center;

            // height: 40%;
            .qr-code {
                margin-top: 3%;
                width: 60%;
                height: auto;
                padding: 10px;
            }

        }

        .qr-code-para {
            margin-top: 2%;
            // padding: 20px;
            text-align: center;
            font-family: Raleway;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.43;
        }

        .shope-link {
            margin-top: 2%;
            // padding: 20px;
            text-align: center;
            font-family: Raleway;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.43;
        }

        .shope-link a {
            font-weight: 600;
        }

        .download-qr-code-btn {
            margin-top: 5%;
            text-align: center;
        }
    }
}