@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

$baseFontSize: 16px;

.main-layout {
    background-color: #1ba0b7;
    height: 100vh;
    width: 100%;
    text-align: center;

    .content {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tab-container {
        display: flex;
        margin: 0 auto;
        align-items: center;
        width: 388px;
        height: 68px;
        padding: 12px;
        align-items: center;
        gap: 8px;
        border-radius: 14px;
        background: #E0F3FA;
        text-align: center;

        @media only screen and (max-width: 768px) {
            width: 90%;
            height: 10%;
            margin: 0 auto;
            text-align: center;
        }

    }

    .button-style {
        display: flex;
        margin: 0 auto;
        text-align: center;
        width: 60%;
        height: 44px;
        font-size: 1rem;
        border-radius: 10px;
        background: #24ACDC;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        background: transparent;
        border: none;
        color: #000;
        font-weight: bolder;
        background-color: #cceff6;

        &.active-button-style {
            background: #24ACDC;
            color: white;
        }

        &:hover {
            cursor: pointer;
        }


        @media only screen and (max-width: 768px) {
            width: 45%;
            margin: 0 auto;
            text-align: center;

            .active-button-style {
                background: #24ACDC;
                color: white;
            }

        }

    }

    .custom-card {
        width: 27rem;
        border-radius: 1.5rem;
        background: #FFF;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.10);
        text-align: center;
    }

    .custom-title {
        display: inline-flex;
        height: 4.754rem;
        padding: 0px 1.551rem;
        align-items: center;
        gap: 1.551rem;
        flex-shrink: 0;

        .Smart-connect-logo {
            width: 260.506px;
            height: 65.167px;
        }

        @media only screen and (max-width:768px) {
            .Smart-connect-logo {
                margin: 0 auto;
                width: 90%;
                height: 80%;
            }
        }
    }

    .section-title {
        color: #1E2226;
        text-align: center;
        font-family: Raleway;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        // margin-top: 1rem;
    }

    .section-pera {
        color: #9A9FA5;
        font-family: 'Inter', sans-serif;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 0;
        margin-bottom: 1.75rem;
    }

    .login-form,
    .signup-form {
        display: flex;
        flex-direction: column;
        //  width: 100%;
        justify-content: center;

        .signup-form-names-fields {
            display: flex;
            justify-content: space-around;
            width: 100%;

            #signupLastName {
                margin-left: 5px;
            }
        }
    }

    .forget-password-link {
        width: 98%;
        text-align: end;
        padding: 5px;
    }

    .form-label {
        margin-bottom: 0.5rem;
        color: #000;
    }

    .form-input {
        // display: flex;
        width: 98%;
        height: 3rem;
        padding: 0 0.875rem;
        align-items: center;
        gap: 0.5rem;
        border-radius: 0.625rem;
        border: 0.0625rem solid #B0E1F2;
        background: #E0F3FA;
        margin-bottom: 1rem;
    }

    .form-button {
        display: flex;
        width: 98%;
        height: 3rem;
        padding: 0.625rem 0.875rem;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        border: none;
        border-radius: 0.625rem;
        background: #FF8000;
        color: #1F2128;
        text-align: center;
        font-family: Raleway;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    p {
        text-align: center;
        color: #000000;
    }

    /* Small screens */
    @media only screen and (max-width: 768px) {
        .main-layout {
            width: 100%;
        }

        // .login-form {
        //     // display: flex;
        //     // flex-direction: column;
        //     width: 100%;
        //     text-align: center;
        // }

        .custom-card {
            width: 95%;
        }

        .form-input,
        .form-button {
            width: 99%;
        }

        .forget-password-link {
            width: 99%;
        }

    }
}