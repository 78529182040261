@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

.reset-password-main-layout {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1ba0b7;

    .reset-password-custom-card {
        width: 27.25rem;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.10);
        height: auto;
        border-radius: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        background-color: #ffffff;

        .reset-password-heading {
            color: #1e2226;
            font-family: 'Raleway', sans-serif;
            font-size: 32px;
            font-weight: 700;
            margin-top: 1.25rem;
            margin-bottom: 1.25rem;
        }

        .reset-password-tab-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 85%;
            height: 64px;
            padding: 12px;
            gap: 8px;
            border-radius: 14px;
            background: #e0f3fa;
            margin-top: 1.25rem;
            margin-bottom: 1.25rem;

            @media only screen and (max-width: 768px) {
                width: 85%;
                height: 10%;
            }
        }

        .button-style {
            display: flex;
            width: 260px;
            height: 44px;
            font-size: 1rem;
            border-radius: 10px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex-shrink: 0;
            background: transparent;
            border: none;
            color: #000;
            font-weight: bolder;

            &.active-button-style {
                background: #24acdc;
                color: white;
            }

            @media only screen and (max-width: 768px) {
                width: 45%;
                margin: 0 auto;
                text-align: center;

                &.active-button-style {
                    background: #24acdc;
                    color: white;
                }
            }
        }

        .reset-password-tab-content {
            width: 100%;
            max-width: 24.25rem;

            .ant-form {
                width: 100%;
            }

            .ant-form-item {
                margin-bottom: 1.25rem;
            }

            .ant-form-item-label {
                label {
                    font-family: 'Raleway', sans-serif;
                    font-size: 1rem;
                    color: #1e2226;
                    font-weight: 700;
                }
            }

            .ant-input {
                height: 44px;
                border-radius: 0.625rem;
            }

            .reset-password-form-enable-button,
            .reset-password-form-disable-button {
                display: flex;
                width: 100%;
                height: 3rem;
                padding: 0.625rem 0.875rem;
                justify-content: center;
                align-items: center;
                gap: 0.5rem;
                border: none;
                border-radius: 0.625rem;
                background: #ffb011;
                color: #1f2128;
                text-align: center;
                font-family: 'Raleway', sans-serif;
                font-size: 1rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 1.25rem;

                &.reset-password-form-disable-button {
                    background: #e0e0e0;
                }
            }
        }
    }

    .section-para-container {
        width: 20rem;
        margin: 0 auto;

        .section-pera {
            color: #9a9fa5;
            font-family: 'Inter', sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-top: 0;
            margin-bottom: 1.75rem;
        }
    }

}