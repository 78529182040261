@import url('https://fonts.googleapis.com/css?family=Roboto');
.sidebar-container {
    position: sticky;
    top: 0;
    height: 100svh;
    z-index: 1;
    background-color: #ffffff;

    &.collapsed .sider {
        width: 0px;
    }
    
    @media (max-width: 992px){
        position: fixed;
        left: 0;
        transition: left 0.8s ease;
        &.collapsed {
            left: -100%;
        }
        &.collapsed .sider {
            width: auto;
        }
    }
}

.sider {
    width: 240px;
    background-color: #f0f2f5;
    transition: width 0.8s ease;
    height: 100svh;
    overflow-x: hidden;

    .close-btn{
        position: absolute;
        right: 8px;
        top: 4px;
        padding: 8px;
        background-color: transparent;
        border: none;
        font-size: 20px;
        cursor: pointer;
        display: none;
        @media (max-width: 992px){
            display: block;
        }
    }

    .sider-content {
        padding: 35px;
    }

    .logo-image {
        width: 150px;
        height: auto;
    }

    .menu {
        list-style: none;
        margin-top: 15%;
        // font-family: 'Roboto', sans-serif;;
        .menu-item {
            width: 100%;
            padding: 10px 0;
            white-space: nowrap;
            cursor: pointer;
            font-size: 18px; /* Increased font size */
            transition: color 0.3s ease; /* Smooth color transition */

            &:hover {
                color: #FF8000;
            }
        }
        &.active {
            color: #FF8000; 
            font-weight: bold; 
        }
    }

    .collabs-button {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 10px;
        cursor: pointer;
        background-color: #FF8000;
        color: #fff;
        border: none;
        border-radius: 5px;
        font-size: 20px; /* Increased font size */
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #FFA94D; /* Lighter shade on hover */
        }
    }
}
