.companyusers-container {
    display: flex;
    // height: 100vh;

}

.compnayusers-content {
    flex: 1;

    .content-header {
        display: flex;
        justify-content: space-between;
        flex: 100%;
        height: 70px;
        background-color: #100739;
        position: sticky;
        top: 0;

        .content-header-headings {
            padding: 20px;
            color: white;
        }

        .content-company-name {
            width: 30%;
            height: 50px;
            padding: 20px;
            font-size: 24px;
            /* Increase font size for emphasis */
            font-weight: bold;
            color: white;
            /* Remove unwanted text decoration */
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            /* Add a subtle text shadow for depth */
            font-family: Nunito Sans;
        }

        @media only screen and (max-width: 768px) {
            .content-company-name {
                width: 200px;
                /* Adjust the width as needed */
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .company-actions {
                width: 600px;
            }
        }

        .company-actions {
            width: 700px;
            text-align: right;
            margin-right: 5px;
            padding: 18px;
        }

        .Add-company-btn {
            background-color: #ff8000;
            border: none;
            color: white;
            border-radius: 5px;
            font-size: 15px;
            cursor: pointer;
            transition: background-color 0.3s ease;
        }

        .Add-company-btn:hover {
            background-color: #ff9933;
            color: white;
        }
    }

    .table-container {
        // max-height: 400px; // Adjust the height as needed
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: #888 transparent;

        .table {
            width: 100%;
            border-collapse: collapse;

            th,
            td {
                border: 1px solid #e8e8e8;
                padding: 20px;
                text-align: center;
                width: 10%;
            }

            th {
                color: #100739;
                background-color: #f0f2f5;
            }

            tbody tr:hover {
                background-color: #e6f7ff;
            }

            .Actions-btns {

                .view-eye-btn,
                .Delete-button,
                .Edit-button,
                .download-button {
                    font-size: 20px;
                    border: none;
                    background-color: transparent;
                    cursor: pointer;
                    text-decoration: underline;
                    margin-left: 14px;

                    &:hover {
                        color: #40a9ff;
                    }
                }

                .view-eye-btn {
                    color: #2d8ed4;
                }

                .Delete-button {
                    color: #ff8000;
                }

                .Edit-button {
                    color: #19acc0;
                }

                .download-button {
                    color: #073907;
                }
            }
        }

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #888;
            border-radius: 5px;
        }

        
    }
}