.leads {
    display: flex;
    height: 100vh;
    padding: 0px;
    margin: 0px;
}

.leads-header {
    display: flex;
    padding-left: 16px;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    height: 70px;
    background-color: #100739;
    position: sticky;
    top: 0;
    
    .sidebar-toggler{
        padding: 6px 16px 6px 4px;
        margin-right: 10px;
        cursor: pointer;
        color: #fff;
        border: none;
        font-size: 18px;
        background-color: transparent;
        border-right: 1px solid rgba(#f0f2f5, 0.3);
      }
    .language-translate-btn{
        padding: 18px;
    }
}
.leads-content {
    width: 100%;
    overflow: hidden;
    /* Hide overflow from the content beneath the header */
}
.scrollable-wrapper{
    height: 100%;
}

.table-container {
    /* Adjust max-height based on fixed header height */
    /* Customizing scrollbar */
    &::-webkit-scrollbar {
        width: 5px;
        /* Width of the scrollbar */
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #949292;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #ffffff;
    }

    .table {
        min-width: 800px;
        width: 100%;
        border-collapse: collapse;
        background-color: #ffffff;

        th,
        td {
            border: 1px solid #e8e8e8;
            padding: 20px;
            text-align: left;
        }

        td {
            cursor: pointer;
        }

        th {
            color: #100739;
            background-color: #f0f2f5;
        }

        tbody tr:hover {
            background-color: #e6f7ff;
        }

        .Actions-btns {
            .Delete-button {
                font-size: 20px;
                border: none;
                background-color: transparent;
                cursor: pointer;
                color: #ff8000;
                text-decoration: underline;
                margin-left: 10px;

                &:hover {
                    color: #40a9ff;
                }
            }

            .Edit-button {
                font-size: 20px;
                border: none;
                background-color: transparent;
                cursor: pointer;
                color: rgb(25, 172, 192);
                text-decoration: underline;
                margin-left: 20px;

                &:hover {
                    color: #40a9ff;
                }
            }
        }
    }
}