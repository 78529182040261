@import url("https://fonts.googleapis.com/css?family=Nunito Sans");
.scrollable-wrapper {
  width: 100%;
  height: auto;
  overflow: auto;
}

.dashboard {
  display: flex;
  width: 100%;

  .content {
    flex: 1;
  width: 100%;

    .content-header {
      display: flex;
      padding-left: 16px;
      width: 100%;
      box-sizing: border-box;
      height: 70px;
      background-color: #100739;
      position: sticky;
      align-items: center;
      top: 0;
      .sidebar-toggler{
        padding: 6px 16px 6px 4px;
        margin-right: 10px;
        cursor: pointer;
        color: #fff;
        border: none;
        font-size: 18px;
        background-color: transparent;
        border-right: 1px solid rgba(#f0f2f5, 0.3);
      }

      .content-header-logo {
        height: 50px;
        width: 60px;
        padding-left: 10px;
      }

      .content-company-name {
        height: 50px;
        padding: 20px;
        font-size: 24px;
        font-weight: bold;
        color: white;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
        font-family: 'Nunito Sans';
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media only screen and (max-width: 768px) {
          width:100px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .AddUser-action {
        flex-grow: 1;
        text-align: right;
        padding: 18px;

        .Add-user-btn {
          background-color: #ff8000;
          border: none;
          color: white;
          border-radius: 5px;
          font-size: 15px;
          cursor: pointer;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #ff9933;
          }
        }
      }
    }

    .table {
      width: 100%;
      border-collapse: collapse;
      background-color: #ffffff;

      th,
      td {
        border: 1px solid #e8e8e8;
        padding: 20px;
        text-align: center;
        width: 10%;
      }

      td {
        cursor: pointer;
      }

      th {
        color: #100739;
        background-color: #f0f2f5;
      }

      tbody tr:hover {
        background-color: #e6f7ff;
      }

      .Actions-btns {
        min-width: 143px;
        .view-eye-btn,
        .Delete-button,
        .Edit-button,.download-button {
          font-size: 20px;
          border: none;
          background-color: transparent;
          cursor: pointer;
          text-decoration: underline;
          margin-left: 14px;
      
          &:hover {
            color: #40a9ff;
          }
        }
      
        .view-eye-btn {
          color: #2d8ed4;
        }
      
        .Delete-button {
          color: #ff8000;
        }
      
        .Edit-button {
          color: #19acc0;
        }
        .download-button{
            color: #073907;
        }
      }
      
    }
  }
}

.icon {
  font-size: 30px;
  margin-right: 10px;
  cursor: pointer;
  transition: color 0.3s ease;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: #1890ff;
  }
}

.facebook-icon {
  color: #3b5998;
}

.instagram-icon {
  background: linear-gradient(45deg, #515bd4, #8134af, #dd2a7b, #feda77, #f58529);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.linkedin-icon {
  color: #0e76a8;
}

.custom-scrollbar {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: 5px;
  }
}
